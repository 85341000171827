import React from "react";

function Loader() {
  return (
    <div id="pre-loader" className="loading-container">
      <div className="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export default Loader;
