import React from "react";

function Footer() {
  return (
    <footer>
      &copy; 2024 Designed by{" "}
      <span>
        <a href="https://www.linkedin.com/in/sakthi-s-p-4aba01165/">
          Sakthi Gomathy
        </a>
      </span>{" "}
      and Developed by Paravada Naveen Teja
    </footer>
  );
}

export default Footer;
