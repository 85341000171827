import { lazy } from "react";

const routePaths = [
  {
    title: "Paravada Naveen Teja - Web Developer",
    path: "/",
    component: lazy(() => import("@/containers/Home/Home.jsx")),
    exact: true,
  },
  {
    title: "Paravada Naveen Teja - Certificates",
    path: "/certs",
    component: lazy(() => import("@/containers/Certificates")),
    exact: true,
  },
];

export default routePaths;
