import { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter as Router } from "connected-react-router";

import history from "@/store/history";
import routePaths from "@/constants/routePaths";
import Loader from "./components/Loader";
import Header from "./components/Header";
import Footer from "./components/Footer";

const App = () => (
  <Router history={history}>
    <Suspense fallback={<Loader />}>
      <Header />
      <Switch>
        {routePaths.map((props, index) => (
          <Route key={index} {...props} />
        ))}
      </Switch>
      <Footer />
    </Suspense>
  </Router>
);

export default App;
