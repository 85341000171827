import React from "react";
import { Link } from "react-router-dom";
import port from "../../constants/portConstants";

function Header() {
  function toggleTheme() {
    var isChecked = document.getElementById("slider").checked;
    if (isChecked) {
      document.body.classList.remove("dark-theme");
      document.getElementById("slider").checked = true;
    } else {
      document.body.classList.add("dark-theme");
      document.getElementById("slider").checked = false;
    }
  }

  function onMenuClick() {
    var menuDropdown = document.getElementById("menu");
    if (menuDropdown.classList.toString().includes("block")) {
      menuDropdown.classList.remove("block");
    } else {
      menuDropdown.classList.add("block");
    }
  }
  return (
    <header>
      <div className="menu-icon">
        <div className="float-right">
          <span className="text-bold text-center primary-color">
            Paravada Naveen Teja
          </span>
        </div>
        <i className="ri-menu-line" onClick={onMenuClick}></i>
      </div>
      <ul id="menu">
        <li>
          <Link to="/">Home</Link>
        </li>
        {/* <li>
          <a href="/#my-recent-works">My Works</a>
        </li>
        <li>
          <a href="/#social">Contact me</a>
        </li> */}
        <li>
          <Link to="/certs">Certifications</Link>
        </li>
        <li>
          <a
            href={`${port.resume}/PARAVADA-NAVEEN-TEJA_RESUME.pdf`}
            download="ParavadaNaveenTeja_Resume.pdf"
            target="_blank"
          >
            Resume
          </a>
        </li>
        <li className="right">
          <label id="switch" className="switch">
            <input
              type="checkbox"
              onClick={toggleTheme}
              id="slider"
              defaultChecked
            />
            <span className="slider round"></span>
          </label>
        </li>
        <li className="right">
          <i className="ri-map-pin-line"></i>
          India
        </li>
      </ul>
    </header>
  );
}

export default Header;
